import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Box, Link } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'DM Sans',
      fontSize: '16px',
      lineHeight: '28px',
      marginBottom: '15px',
    },
    heart: {
      marginLeft: '8px',
      marginRight: '8px',
      marginBottom: '-3px',
    },
    sviText: {},
    linkText: {
      color: '#1a1b1f',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
)

const Footer: React.FC = () => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      {/*
        On target="_blank" security
            (1) https://mathiasbynens.github.io/rel-noopener/
        Some thoughts on why sometimes this is not a good solution
            (2) https://css-tricks.com/use-target_blank/
          */}
      <Link
        className={classes.linkText}
        href="https://www.siliconvalleyinsight.com?utm_source=hsmba-app"
        target="_blank"
        rel="noopener"
      >
        <strong>Built with</strong>
        <img className={classes.heart} src="/heart_outline.svg" alt="Built with love by svi.io" />
        <strong>by SVI.IO</strong>
      </Link>
    </Box>
  )
}

export default Footer
