import React, { useEffect } from 'react'
import { NextPage } from 'next'
import Router from 'next/router'
import fetch from 'isomorphic-unfetch'
import cookies from 'next-cookies'
import firebase from 'firebase/app'
import 'firebase/auth'
import LoginForm from '../components/LoginForm'
import LoginMode from '../lib/LoginMode'
import initFirebase from '../src/firebase/initFirebase'
import Page from '../components/Page'
import { UserType } from '../lib/UserType'
import { reset as resetAnalyticsSession, trackMagicLinkRequestedEvent } from '../src/analytics'
import log from '../src/logger'

// Firebase app is a singleton so it's safe to call init multiple times
// and we need to call it to ensure that firebase is initialised
initFirebase()

const sendLoginEmail = async (email: string, recordId: string, userType: UserType) => {
  const { protocol, hostname, port } = window.location
  const urlPort = process.env.ENV === 'development' && port ? `:${port}` : ''
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: `${protocol}//${hostname}${urlPort}/verify-login?recordId=${recordId}&userType=${userType}`,
    // This must be true.
    handleCodeInApp: true,
  }

  return firebase
    .auth()
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(function() {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      localStorage.setItem('emailForSignIn', email)

      trackMagicLinkRequestedEvent(email, userType)
    })
}

const handleLogin = async (email: string, mode: LoginMode): Promise<string> => {
  const response = await fetch('/api/user/check-user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      loginMode: mode,
    }),
  })

  if (response.status === 200) {
    const { recordId, userType } = await response.json()
    await sendLoginEmail(email, recordId, userType)
    return Promise.resolve('Email with link sent')
  }

  if (response.status === 404) {
    const error = await response.text()
    log('User not found', { email, mode })
    throw new Error(error)
  }

  return Promise.reject(new Error('User not found'))
}

interface Props {
  error?: string
}

const Login: NextPage<Props> = ({ error }) => {
  // We want to clean up Segment session upon user logout.
  // Login component only appears if user is not logged in or has just logged out
  // or session expired and we forced logged out. So it makes sense to call reset
  // in useEffect() of Login
  useEffect(() => {
    resetAnalyticsSession()
  }, [])

  return (
    <Page>
      <LoginForm handleLogin={handleLogin} error={error} />
    </Page>
  )
}

Login.getInitialProps = ctx => {
  // If we have a session or user data then most likely user is logged in
  // the session might be expired or invalid though but our job here is to do a lightweight check
  // other pages will make use of withAuth HOC to validate and logout if needed
  if (ctx.req) {
    const { session } = cookies(ctx)
    if (session) {
      ctx.res.writeHead(302, {
        Location: '/home',
        'Content-Type': 'text/html; charset=utf-8',
      })
      ctx.res.end()
    }
  } else {
    const clientUserData = window?.localStorage?.getItem('currentUser')
    if (clientUserData) {
      Router.push('/home')
    }
  }

  const error = ctx.query.error as string

  return {
    error,
  }
}

export default Login
