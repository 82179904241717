import React, { FC, useState } from 'react'
import { createStyles, makeStyles, withStyles, Theme, createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import {
  Backdrop,
  Box,
  TextField,
  Button,
  Link,
  Typography,
  Container,
  CircularProgress,
  CssBaseline,
  Fade,
  Modal,
  Paper,
  Snackbar,
  Tabs,
  Tab,
} from '@material-ui/core'
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert'
import defaultTheme from '../src/theme'
import LoginMode from '../lib/LoginMode'

const StyledTab = withStyles({
  root: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: 8,
    '&$selected': {
      color: '#0057FF',
    },
  },
  selected: {
    color: '#0057FF',
  },
})(Tab)

const StyledTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#0057FF',
  },
})(Tabs)

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

interface Props {
  handleLogin: (email: string, mode: LoginMode) => Promise<string>
  error?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    loginContainer: {
      padding: 24,
      backgroundColor: 'white',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
      borderRadius: '6px',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      // margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    button: {
      backgroundColor: '#1940dd',
      color: 'white',
      '&:hover': {
        backgroundColor: '#001774',
      },
      width: '110px',
    },
    img: {
      position: 'absolute',
      // top: '50%',
      left: '50%',
      width: '315px',
      height: '357px',
    },
    helpModal: {
      borderRadius: '6px',
      alignSelf: 'center',
      justifySelf: 'center',
      [theme.breakpoints.up('sm')]: {
        padding: '150px',
        display: 'inline-table !important',
      },
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        display: 'flex',
      },
    },
    helpContent: {
      border: 0,
      outline: 0,
      padding: '30px',
    },
    helpContentText: {
      [theme.breakpoints.up('xs')]: {
        height: '100%',
        overflowY: 'none',
        boxShadow: 0,
      },
      [theme.breakpoints.down('xs')]: {
        height: '85%',
        overflowY: 'scroll',
        boxShadow: 'inset 0 -5px 5px -5px rgba(0, 0, 0, 0.2)',
      },
    },
    helpButtonBox: {
      marginTop: '20px',
    },
  }),
)

const Login: FC<Props> = ({ handleLogin, error }) => {
  const classes = useStyles()

  const [loginError, setLoginError] = useState(null)
  const [mode, setMode] = useState(LoginMode.Student)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({ open: !!error, message: error ?? '', severity: error ? 'error' : '' })
  const [successMessage, setSuccessMessage] = useState(false)
  const [helpModalOpen, setHelpModalOpen] = useState(false)

  const handleLoginClick = async () => {
    setLoginError(null)
    setSuccessMessage(false)

    const caseInsensitiveEmail = email.toLowerCase()

    if (
      mode === LoginMode.Student &&
      !(
        caseInsensitiveEmail.endsWith('.edu') ||
        caseInsensitiveEmail.endsWith('svi.io') ||
        caseInsensitiveEmail.endsWith('siliconvalleyinsight.com')
      )
    ) {
      setSnackbar({ open: true, message: 'Please enter .edu email', severity: 'warning' })
      return
    }
    if (email === '') {
      setSnackbar({ open: true, message: 'Enter a valid email address', severity: 'warning' })
      return
    }
    setLoading(true)
    try {
      const result = await handleLogin(caseInsensitiveEmail, mode)
      setSnackbar({ open: true, message: result, severity: 'success' })
      setSuccessMessage(true)
    } catch (e) {
      setSnackbar({ open: true, message: e.message, severity: 'error' })
      setLoginError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const close = () => {
    setSnackbar({ open: false, message: '', severity: 'warning' })
  }

  const handleModeChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setMode(LoginMode[LoginMode[newValue]])
  }

  const onChangeEmail = event => {
    setEmail(event.target.value)
    setLoginError(null)
  }

  const bgColor = mode === LoginMode.Student ? '#F8ECD4' : '#FAFAFA'
  const theme = { ...defaultTheme }
  theme.palette.background.default = bgColor

  const label = mode === LoginMode.Student ? '.edu email' : 'email'
  const signupLink = mode === LoginMode.Student ? '/mba-form' : '/company-form'

  /* eslint-disable-next-line react/no-unescaped-entities */
  let LoginFooter = <Link href={`https://www.hireastartupmba.com${signupLink}`}> Don't have an account yet? </Link>
  if (successMessage) {
    LoginFooter = (
      <Typography>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Thanks! We've sent you an email with a magic link to sign in. (If you don't see the email, please double-check
        your spam folder.)
      </Typography>
    )
  }
  if (loginError === 'Account is blocked') {
    LoginFooter = (
      <Typography>
        Your account has been suspended. Please, message us using the chat widget to resolve this issue
      </Typography>
    )
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container className={classes.container} maxWidth="sm">
          {/* <Box className={classes.container} maxWidth="sm"> */}
          <Box className={classes.loginContainer}>
            <Box mb={2}>
              <Typography variant="h1" gutterBottom>
                Login
              </Typography>
            </Box>
            <Box mb={4}>
              <StyledTabs value={mode} onChange={handleModeChange}>
                <StyledTab label="Login as an MBA" />
                <StyledTab label="Login as a Startup" />
              </StyledTabs>
            </Box>
            <Box mb={3}>
              <TextField
                type="email"
                value={email}
                placeholder={label}
                onChange={onChangeEmail}
                fullWidth
                variant="outlined"
                label={label}
              />
            </Box>
            <Box mb={3}>
              <div className={classes.buttonContainer}>
                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    // className={buttonClassname}
                    disabled={loading}
                    onClick={handleLoginClick}
                  >
                    Log in
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
            </Box>
            <Box mb={3}>
              {LoginFooter}
              {email && (
                <>
                  <br />
                  <Fade in={!!email}>
                    <Link href="#" onClick={() => setHelpModalOpen(true)}>
                      Need help logging in?
                    </Link>
                  </Fade>
                </>
              )}
            </Box>
          </Box>
          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={close}>
              <Alert severity={snackbar.severity as Color}>{snackbar.message}</Alert>
            </Snackbar>
          )}
          <Modal
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            open={helpModalOpen}
            onClose={() => setHelpModalOpen(false)}
            className={classes.helpModal}
          >
            <Fade in={helpModalOpen}>
              <Paper className={classes.helpContent}>
                <Typography variant="h5">Having trouble logging in?</Typography>
                <Box className={classes.helpContentText}>
                  <Typography>
                    <ul>
                      <li>
                        <b>Can’t log in?</b>
                        <br />
                        Make sure you are typing your email correctly.
                      </li>
                    </ul>
                  </Typography>
                  <Typography>
                    <ul>
                      <li>
                        <b>Can’t get the magic link to work?</b>
                        <br /> Make sure you are opening the link in the same web browser that you submitted your email
                        in. You may need to login on a browser, go to your email, copy the link, then open it in the
                        same browser that you submitted the email in.
                      </li>
                    </ul>
                  </Typography>
                  <Typography>
                    <ul>
                      <li>
                        <b>Did not receive a magic link?</b>
                        <br /> Check your spam folder ;)
                      </li>
                    </ul>
                  </Typography>
                  <Typography>
                    <ul>
                      <li>
                        <b>Can’t find magic link in inbox or spam folder?</b>
                        <br /> We’ve found that a number of schools have auto-filters. Try adding&nbsp;
                        <a href="mailto:noreply@hireastartupmba.com">noreply@hireastartupmba.com</a> to your contacts
                        (magic link will come from this email). Save the contact, then retry logging in to get the magic
                        link.
                      </li>
                    </ul>
                  </Typography>

                  <Typography>
                    If none of the above works,{' '}
                    <a href="mailto:hireastartupmba-project@siliconvalleyinsight.com">email us</a> and we’ll get to the
                    bottom of it.
                  </Typography>
                </Box>
                <Box className={classes.helpButtonBox}>
                  <div className={classes.buttonContainer}>
                    <div className={classes.wrapper}>
                      <Button variant="contained" className={classes.button} onClick={() => setHelpModalOpen(false)}>
                        Got it!
                      </Button>
                    </div>
                  </div>
                </Box>
              </Paper>
            </Fade>
          </Modal>
        </Container>
      </ThemeProvider>
    </>
  )
}

export default Login
