import React from 'react'
import { Box, Container } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import NavigationBar from '../components/appBar'
import Footer from '../components/Footer'
import { CurrentUser } from '../src/pageWrappers/withAuth'

interface Props {
  currentUser?: CurrentUser
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    contents: {
      flex: 1,
    },
  }),
)

const Page: React.FC<Props> = ({ currentUser, children }) => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <NavigationBar currentUser={currentUser} />
      <Container className={classes.contents}>{children}</Container>
      <Footer />
    </Box>
  )
}

export default Page
