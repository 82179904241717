import Rollbar from 'rollbar'
import pino from 'pino'
import logflare from 'pino-logflare'

// This is client Rollbar object
// Rollbar is configured and injected by Segment automatically
// See Segment dashboard for configuration
const getRollbar = (): Rollbar => {
  // disable logger for local builds
  if (window.location.hostname === 'localhost') return null
  return window.Rollbar
}

const log = (title: string, params: any): void => {
  const logger = getRollbar()
  if (!logger) return

  logger.info(title, params)
}

export const createLogflareLogger = () => {
  // create pino-logflare stream
  const stream = logflare.createWriteStream({
    apiKey: 'nzc1dAr54kGo',
    source: '223e9195-01fa-43d8-ac13-b8b88db111c5',
    size: 1,
  })

  // create pino loggger
  const logger = pino({}, stream)

  return logger
}

export default log
